import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateRiskForm.scss";
import { FaArrowLeft } from "react-icons/fa";

const CreateRiskForm = () => {
  const [formData, setFormData] = useState({
    riskName: "",
    dateAdded: "",
    source: "",
    likelihood: "",
    consequence: "",
    riskScore: 0,
    riskRating: "",
    riskSummary: "",
    prevention: "",
    mitigation: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      riskScore: formData.likelihood * formData.consequence,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Logic to handle form submission
  };

  return (
    <div className="create-risk-form">
      <div className="create-risk-form-top">
        <div
          className="create-risk-form-top-icon"
          onClick={() => navigate("/risk/admin/risk-details/")}
        >
          <FaArrowLeft />
        </div>
        <div className="create-risk-form-top-title">
          <div className="create-risk-form-top-title-text">Register a risk</div>
          <div className="create-risk-form-top-title-template-name">
            {/* {template.name} */}
          </div>
        </div>
      </div>
      <form className="create-risk-form-body" onSubmit={handleSubmit}>

        {/* Risk Details Section */}
        <div className="form-section">
          <div className="form-section-heading">Risk Details</div>
          <div className="form-fields">
            <div className="half-width">
              <label>Name of Risk</label>
              <input
                type="text"
                name="riskName"
                value={formData.riskName}
                onChange={handleChange}
              />
            </div>
            <div className="half-width">
              <label>Date Added</label>
              <input
                type="date"
                name="dateAdded"
                value={formData.dateAdded}
                onChange={handleChange}
              />
            </div>
            <div className="half-width">
              <label>Source</label>
              <input
                type="text"
                name="source"
                value={formData.source}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* Risk Evaluation Section */}
        <div className="form-section">
          <div className="form-section-heading">Risk Evaluation</div>
          <div className="form-fields">
            <div className="half-width">
              <label>Likelihood</label>
              <input
                type="number"
                name="likelihood"
                value={formData.likelihood}
                onChange={handleChange}
              />
            </div>
            <div className="half-width">
              <label>Consequence</label>
              <input
                type="number"
                name="consequence"
                value={formData.consequence}
                onChange={handleChange}
              />
            </div>
            <div className="half-width">
              <label>Risk Score (Likelihood × Consequence)</label>
              <input
                type="number"
                name="riskScore"
                value={formData.riskScore}
                readOnly
              />
            </div>
            <div className="half-width">
              <label>Risk Rating</label>
              <select
                name="riskRating"
                value={formData.riskRating}
                onChange={handleChange}
              >
                <option value="">Select Rating</option>
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
                <option value="critical">Critical</option>
              </select>
            </div>
          </div>
        </div>

        {/* Risk Summary Section */}
        <div className="form-section">
          <div className="form-section-heading">Risk Summary</div>
          <div className="form-fields">
            <div className="full-width">
              <label>Risk Summary</label>
              <textarea
                name="riskSummary"
                value={formData.riskSummary}
                onChange={handleChange}
              />
            </div>
            <div className="full-width">
              <label>What to Do to Prevent?</label>
              <textarea
                name="prevention"
                value={formData.prevention}
                onChange={handleChange}
              />
            </div>
            <div className="full-width">
              <label>What Can Be Done to Mitigate Consequences?</label>
              <textarea
                name="mitigation"
                value={formData.mitigation}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="form-footer">
          <button type="submit">Submit Risk Report</button>
        </div>
      </form>
    </div>
  );
};

export default CreateRiskForm;
