import React, { useState } from "react";
import "./ForgotPasswordPopup.scss";
import { forgotPassword } from "../services/api.services"; 
import toast from "react-hot-toast";

const ForgotPasswordPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const resetForm = () => {
    setEmail("");
    setError("");
    setMessage("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(""); 
  };

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      setError("Enter a valid email address");
      return;
    }

    
    const toastId = toast.loading("Sending password reset request...");
    try {
      
      const response = await forgotPassword(email);
      toast.dismiss(toastId);
      if (response.data.isSuccess) {

        // setMessage("Password reset email sent successfully!");
        toast.success("Password reset email sent successfully!, please check your email.");
        togglePopup(); 
        resetForm();
      } else {
        setError("User not found"); 
      }
    } catch (error) {
      toast.dismiss(toastId);
      setError("Something went wrong. Please try again.");
      console.error(error);
    }
  };

  return (
    <div>
      <div className="forgot-password-popup-link" onClick={togglePopup}>
        Forgot Your Password?
      </div>

      {isOpen && (
        <div className="forgot-password-popup-overlay popup-open">
          <div className="forgot-password-popup-body popup-open">
            <div className="forgot-password-popup-body-title">Forgot your password?</div>
            <div className="forgot-password-popup-body-text">
              Enter your registered email address, and we'll send you a link to
              reset your password.
            </div>

            <input
              type="email"
              id="email"
              placeholder="Enter email address*"
              value={email}
              onChange={handleEmailChange}
            />
            {error && <div className="forgot-password-popup-error">{error}</div>}
            {/* {message && <div className="popup-success">{message}</div>} */}
            <button className="forgot-password-popup-body-submit" onClick={handleSubmit}>
              Request password reset
            </button>
            <button className="forgot-password-popup-body-close" onClick={() => { togglePopup(); resetForm(); }}>
              Back to Sign in
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPasswordPopup;