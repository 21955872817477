import React, { useState, useContext } from "react";
import "./UserHeader.scss";
import logo from "../../learning/images/icons/logo.png";
import tabLogo from "../../learning/images/icons/mobile_logo.png";
import user_avatar from "../../learning/images/icons/user_avatar.jpg";
import { useNavigate } from "react-router-dom";
import { FaBars, FaRegClock } from "react-icons/fa";
import superAppIcon from "../../learning/images/icons/admin/superapp/superapp.png";
import complianceApp from "../../learning/images/icons/admin/superapp/compliance.png";
import learningApp from "../../learning/images/icons/admin/superapp/learning.png";
import policyApp from "../../learning/images/icons/admin/superapp/policy.png";
import incidentApp from "../../learning/images/icons/admin/superapp/incident.png";
import riskApp from "../../learning/images/icons/admin/superapp/risk.png";
import usersApp from "../../learning/images/icons/admin/superapp/users.png";
import { AuthContext } from "../../App";
import toast from "react-hot-toast";
import AdminToggler from "./AdminToggler";

const UserHeader = ({
  toggleSidebar,
  handleHamburgerNav,
  creditBalance,
  isSubscription,
}) => {
  const [superAppIsOpen, setSuperAppIsOpen] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [selectedApp, setSelectedApp] = useState(
    localStorage.getItem("currentApp")
  );
  const navigate = useNavigate();
  const adminRoutes = {
    users: "/users/admin/",
    learning: "/learning/admin/",
    risk: "/risk/admin/",
    policy: "/policy/admin/",
    incident: "/incident/admin/",
    compliance: "/compliance/admin/",
  };

  const userRoutes = {
    users: "/users/",
    learning: "/",
    risk: "/",
    policy: "/policy/user/",
    incident: "/",
    compliance: "/",
  };

  const handleAppSwitch = (appKey) => {
    setSelectedApp(appKey);
    localStorage.setItem("currentApp", appKey);
    navigate(userRoutes[appKey]);
    dispatch({ type: "SET_CURRENT_APP", payload: appKey });
  };
  const handleToggleUserType = () => {
    const newUserType = "admin";
    const newRoute = adminRoutes[selectedApp];

    dispatch({ type: "TOGGLE_USER_TYPE" });
    localStorage.setItem("currentUserType", newUserType);

    navigate(newRoute);
  };

  const toggleSuperAppButton = () => {
    if (!superAppIsOpen) {
      document.getElementById("super_app_button").classList.add("active");
      document.getElementById("supper_app_container").style.display = "block";
    } else {
      document.getElementById("super_app_button").classList.remove("active");
      document.getElementById("supper_app_container").style.display = "none";
    }
    setSuperAppIsOpen(!superAppIsOpen);
  };

  const handleComingSoon = () => {
    toast.custom(
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px 20px",
          borderRadius: "8px",
          backgroundColor: "#579bd6",
          color: "#fff",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <FaRegClock style={{ marginRight: "8px" }} />
        <span>Feature coming soon! Stay tuned.</span>
      </div>,
      {
        duration: 1000, // Display duration in milliseconds
        position: "top-center",
      }
    );
  };

  return (
    <div className="user-header">
      {/* <FaBars className="user-header-hambuger" onClick={() => toggleSidebar()} /> */}
      <div className="user-header-hamburger" onClick={() => toggleSidebar()}>
        <span className="user-header-hamburger-bar"></span>
        <span className="user-header-hamburger-bar"></span>
        <span className="user-header-hamburger-bar"></span>
      </div>
      <div className="user-header-logo">
        <div className="user-header-logo-img">
          <img src={logo} alt="logo" />
        </div>
        {/* <div className="user-header-logo-tab">
          <img src={tabLogo} alt="logo" />
        </div> */}
      </div>
      <div className="user-header-user">
        {!isSubscription && (
          <div className="user-header-user-credits">
            {creditBalance && `Credits: ${creditBalance}`}
          </div>
        )}
        {state.is_admin === true && (
          <AdminToggler checked={true} onChange={handleToggleUserType} />
        )}
        <div className="user-header-user-superapp">
          <div
            id="super_app_button"
            className="user-header-user-superapp-button"
            onClick={() => toggleSuperAppButton()}
          >
            <img src={superAppIcon} alt="super app"></img>
          </div>
          <div
            id="supper_app_container"
            className="user-header-user-superapp-container"
          >
            <div className="user-header-user-superapp-container-row">
              <div
                className={`user-header-user-superapp-container-row-item ${
                  ["learning", "risk", "incident"].includes(selectedApp)
                    ? "selected-app"
                    : ""
                }`}
                onClick={() => handleAppSwitch("learning")}
              >
                <div
                  className="user-header-user-superapp-container-row-item-icon"
                  style={{ backgroundColor: "#CCF4F7" }}
                >
                  <img src={learningApp} alt="learning app"></img>
                </div>
                <div className="user-header-user-superapp-container-row-item-title">
                  Learning
                </div>
              </div>
              <div
                className={`user-header-user-superapp-container-row-item ${
                  selectedApp === "policy" ? "selected-app" : ""
                }`}
                onClick={() => handleAppSwitch("policy")}
              >
                <div
                  className="user-header-user-superapp-container-row-item-icon"
                  style={{ backgroundColor: "#FFF0D3" }}
                >
                  <img src={policyApp} alt="policy app"></img>
                </div>
                <div className="user-header-user-superapp-container-row-item-title">
                  Policy
                </div>
              </div>
              {/* <div
                className="user-header-user-superapp-container-row-item"
                onClick={() => handleComingSoon()}
                style={{ opacity: "40%" }}
              >
                <div
                  className="user-header-user-superapp-container-row-item-icon"
                  style={{ backgroundColor: "rgb(190 168 255 / 37%)" }}
                >
                  <img src={usersApp} alt="learning app"></img>
                </div>
                <div className="user-header-user-superapp-container-row-item-title">
                  Users
                </div>
              </div> */}

              <div
                className="user-header-user-superapp-container-row-item"
                onClick={() => handleComingSoon()}
                style={{ opacity: "40%" }}
              >
                <div
                  className="user-header-user-superapp-container-row-item-icon"
                  style={{ backgroundColor: "#FBE7FF" }}
                >
                  <img src={riskApp} alt="risk app"></img>
                </div>
                <div className="user-header-user-superapp-container-row-item-title">
                  Risk
                </div>
              </div>

              <div
                className="user-header-user-superapp-container-row-item"
                onClick={() => handleComingSoon()}
                style={{ opacity: "40%" }}
              >
                <div
                  className="user-header-user-superapp-container-row-item-icon"
                  style={{ backgroundColor: "rgb(194 96 97 / 24%)" }}
                >
                  <img src={incidentApp} alt="incident app"></img>
                </div>
                <div className="user-header-user-superapp-container-row-item-title">
                  Incidents
                </div>
              </div>
              {/* <div
                className="user-header-user-superapp-container-row-item"
                onClick={() => handleComingSoon()}
                style={{ opacity: "40%" }}
              >
                <div
                  className="user-header-user-superapp-container-row-item-icon"
                  style={{ backgroundColor: "#CCF7D8" }}
                >
                  <img src={complianceApp} alt="compliance app"></img>
                </div>
                <div className="user-header-user-superapp-container-row-item-title">
                  Compliance
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="user-header-user-avatar">
          {/* <div className="user-header-user-avatar-name">
            {userDetails && userDetails.full_name}
          </div> */}
          <div className="user-header-user-avatar-image">
            <img src={user_avatar} alt="picture" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
