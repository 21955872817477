import React, { useState, useEffect, useContext, useRef } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import AppLoader from "../../../learning/user/components/AppLoader";
import SearchBox from "../../../learning/user/components/SearchBox";
import UserPolicyItems from "../components/UserPolicyItems";
import AppPagination from "../../../learning/admin/components/AppPagination";
import {
  getUserPolicytData,
  searchUserPolicyData,
  updateUserPolicy
} from "../services/api.services";
import "./UserPolicy.scss";

const UserPolicy = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [policyNames, setPolicyNames] = useState([]);
  const [status, setStatus] = useState(null);
  const [count, setCount] = useState(8);
  const [isRead, setIsRead] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [policyData, setPolicyData] = useState([]);


  var recordsPerPage = 10;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    if (searchText) {
      findUserPolicy(searchText);
    } else {
      fetchUserPolicy();
    }
  }, [isRead, searchText, pageNo]);

  const fetchUserPolicy = async () => {
    // setLoader(false);
    try {
      let response;
      if (isRead!== null) {
        response = await getUserPolicytData(
          header,
          isRead,
          pageNo,
          recordsPerPage
        );
      } else {
        response = await getUserPolicytData(
          header,
          null,
          pageNo,
          recordsPerPage
        );
      }

      if (response.status === 200) {
        setPolicyData(response.data.results);
        setCount(response.data.count);
        setDataCount(response.data.count);
        if (status === null) {
          const policyNames = response.data.results.map((policy) =>
            policy.policy.name.length !== 0
              ? policy.policy.name
              : policy.policy.policy_number
          );
          setPolicyNames(policyNames);
        }
        setLoader(true);
      } else {
        toast.error("Failed to fetch Policy data");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error fetching policy data: " + error.message);
      setLoader(false);
    }
  };

  const findUserPolicy = async (searchKey) => {
    // setLoader(false);
    try {
      let response;
      if (isRead!== null) {
        response = await searchUserPolicyData(
          header,
          searchKey,
          isRead,
          pageNo,
          recordsPerPage
        );
      } else {
        response = await searchUserPolicyData(
          header,
          searchKey,
          null,
          pageNo,
          recordsPerPage
        );
      }

      if (response.status === 200) {
        setPolicyData(response.data.results);
        setCount(response.data.count);
        setDataCount(response.data.count);
        setLoader(true);
      } else {
        toast.error("Failed to Search Course data");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error searching Course data: " + error.message);
      setLoader(false);
    }
  };

  let header = {
    Authorization: "Token " + state.token,
  };

  const handleCourseSelect = (courseName) => {
    setSearchText(courseName);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  return (
    <>
      {!loader ? (
        <AppLoader />
      ) : (
        <div className="user-policy">
          <div className="user-policy-title">
            <div className="user-policy-title-text">Policy</div>
            <div className="user-policy-title-search-box">
              <SearchBox
                searchData={policyNames}
                onValueClick={handleCourseSelect}
                onClearSearch={handleClearSearch}
              />
            </div>
          </div>

          <UserPolicyItems
            userPolicies={policyData}
            header={header}
            setStatus={setIsRead}
            status={isRead}
            policyCount={dataCount}
          />

          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      )}
    </>
  );
};

export default UserPolicy;
