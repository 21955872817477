import {react, useState, useEffect, useContext} from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { FaArrowLeft, FaDownload, FaCheckDouble } from "react-icons/fa";
import { updateUserPolicy } from "../services/api.services";
import { AuthContext } from "../../../App";
import AppPdfViewer from "./AppPdfViewer";
import "./UserPolicyViewer.scss";

const UserPolicyViewer = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [key, setKey] = useState(false);
  let header = {
    Authorization: "Token " + state.token,
  };
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const editUserPolicy = async (policyId) => {
    const updatedData = { is_read : true };

    const response = await updateUserPolicy(
      header,
      policyId,
      updatedData
    );

    if (response.status === 200) {
      toast.success("Marked as Read!!");
      setKey(true);
    } else {
      toast.error("Updation Failed!!");
      console.error(response.message);
    }
  };
  return (
    <div className="user-policy-viewer">
      <div className="user-policy-viewer-top">
        <div
          className="user-policy-viewer-top-icon"
          onClick={() => navigate("/policy/user/")}
        >
          <FaArrowLeft />
        </div>
        <div className="user-policy-viewer-top-title">
          <div className="user-policy-viewer-top-title-text">Policy</div>/
          <div className="user-policy-viewer-top-title-template-name">
            {data.policy.name.length !== 0
              ? data.policy.name
              : data.policy.policy_number}
            {/* {data.name} */}
          </div>
        </div>
      </div>
      <div className="user-policy-viewer-body">
        <div className="user-policy-viewer-body-pdfcontainer">
          <div className="user-policy-viewer-body-pdfcontainer-head">
            <div className="user-policy-viewer-body-pdfcontainer-head-top">
              <div className="user-policy-viewer-body-pdfcontainer-head-top-name">
                {data.name}
              </div>
              <div className="user-policy-viewer-body-pdfcontainer-head-top-date">
                Last modidied on {formatDate(data.updated_on)}
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                margin: "15px auto",
                border: "none",
                borderTop: "1px solid rgb(167 219 224 / 19%)",
              }}
            />
            <div className="user-policy-viewer-body-pdfcontainer-head-info">
              <div className="user-policy-viewer-body-pdfcontainer-head-info-item">
                <div className="user-policy-viewer-body-pdfcontainer-head-info-item-key">
                  Type :{" "}
                </div>
                <div className="user-policy-viewer-body-pdfcontainer-head-info-item-value">
                  {/* {data.type} */} Clinical
                </div>
              </div>
              <div className="user-policy-viewer-body-pdfcontainer-head-info-item">
                <div className="user-policy-viewer-body-pdfcontainer-head-info-item-key">
                  Author :{" "}
                </div>
                <div className="user-policy-viewer-body-pdfcontainer-head-info-item-value">
                  {/* {data.author} */} John Doe
                </div>
              </div>
              <div className="user-policy-viewer-body-pdfcontainer-head-info-item">
                <div className="user-policy-viewer-body-pdfcontainer-head-info-item-key">
                  Status :{" "}
                </div>
                <div className="user-policy-viewer-body-pdfcontainer-head-info-item-value">
                  {data.is_read || key == true ? "Read" : "Pending"}
                </div>
              </div>
              <div className="user-policy-viewer-body-pdfcontainer-head-info-item">
                <div className="user-policy-viewer-body-pdfcontainer-head-info-item-key">
                  Version :{" "}
                </div>
                <div className="user-policy-viewer-body-pdfcontainer-head-info-item-value">
                  {/* {data.version} */} 1.0
                </div>
              </div>
            </div>
          </div>
          <div className="user-policy-viewer-body-pdfcontainer-body">
            <AppPdfViewer subjectfileUrl={data.policy.file} />
          </div>
          <div className="user-policy-viewer-body-pdfcontainer-bottom">
            <button className="primary-button">
              Download
              <FaDownload />
            </button>
            {!data.is_read && key == false && (
              <button className="secondary-button" onClick={()=>editUserPolicy(data.id)}>
                Mark as read <FaCheckDouble />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPolicyViewer;
