import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Course from "../views/Course";
import Home from "../views/Home";
import Settings from "../views/Settings";
import Subject from "../views/Subject";
import MockTest from "../views/MockTest";
import Certificate from "../views/Certificate";
import CourseSelector from "../views/CourseSelector";
// import "./AppBodyRouter.scss";

const AppBodyRouter = ({ courseData, userCourseId }) => {

  return (
    <>
      {courseData && (
        <Routes>
          {/* <Route path="/" element={<Outlet />}> */}
          <Route index element={<Home userCourseId={userCourseId} isScorm={courseData.course_data.is_scorm} />} />
          <Route path="Settings" element={<Settings />} />
          <Route path="Mock Tests" element={<MockTest />} />
          <Route path="Certificate" element={<Certificate courseId={courseData.course_data.id}/>} />

          {/* Routes for the dynamic navbar */}
          {courseData["course_data"].modules.map((data, index) => {
            if (data.name === "Course" && data.position === 0) {
              return (
                <Route
                  key={index}
                  exact
                  path={`${data.name}`}
                  element={
                    <Course
                      courseData={data}
                      currentCourseName={courseData.course_data.name}
                    />
                  }
                />
              );
            } else {
              return (
                <Route
                  key={index}
                  exact
                  path={`${data.name}`}
                  element={
                    <Subject
                      moduleId={data.id}
                      moduleName={data.name}
                      moduleData={courseData["course_data"].modules}
                      courseName={courseData.course_data.name}
                    />
                  }
                />
              );
            }
          })}
          </Routes>
      )}
    </>
  );
};

export default AppBodyRouter;
