import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "../components/AdminSidebar";
import CommonSidebar from "../../../common/components/CommonSidebar";
import AdminHeader from "../components/AdminHeader";
import AdminRoutes from "../components/AdminRoutes";
import dashIcon from '../../images/icons/dashboard.png';
import classListoursesIcon from '../../images/icons/admin/sidebar/courses.png';
import traineesIcon from '../../images/icons/admin/sidebar/trainees.png';
import creditIcon from '../../images/icons/admin/sidebar/credit.png';
import requestsIcon from "../../images/icons/request.png";
import { AuthContext } from "../../../App";
import "./AdminDashboard.scss";
import { CreditProvider, useCredit } from "../components/CreditContext";

const AdminDashboardContent = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { creditBalance, fetchCreditDetails, isSubscription } = useCredit();
  const navigate = useNavigate();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [navbarData, setNavbarData] = useState([
    {
      name: "Dashboard",
      icon: dashIcon,
      onClick: () => navigate("/learning/admin/"),
    },
    {
      name: "All Courses",
      icon: classListoursesIcon,
      onClick: () => navigate("/learning/admin/courses"),
    },
    {
      name: "Trainees",
      icon: traineesIcon,
      onClick: () => navigate("/learning/admin/trainees"),
    },
    {
      name: "Purchase Credits",
      icon: creditIcon,
      onClick: () => navigate("/learning/admin/credits/"),
    },
    {
      name: "Requests",
      icon: requestsIcon,
      onClick: () => navigate("/learning/admin/requests/"),
    },
  ]);

  const toggleSidebar = () => {
    setOpenMobileNav(!openMobileNav);
  };

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  return (
    <div className="admin-dashboard">
      {/* <div className="admin-dashboard-header">
        <AdminHeader toggleSidebar={toggleSidebar} creditBalance={creditBalance} isSubscription={isSubscription} />
      </div> */}
      <div className="admin-dashboard-body">
        <div className="admin-dashboard-body-sidebar">
        <CommonSidebar
            setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
            navbarData={navbarData}
            deviceWidth={deviceWidth}
            openMobileNav={openMobileNav}
          />
        </div>
        <div className="admin-dashboard-body-content">
          <div className="admin-dashboard-body-content-header">
            <AdminHeader toggleSidebar={toggleSidebar} creditBalance={creditBalance} isSubscription={isSubscription} />
          </div>
          <div className="admin-dashboard-body-content-elements">
            <AdminRoutes fetchCreditDetails={fetchCreditDetails} isSubscription={isSubscription} /> 
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminDashboard = () => (
  <CreditProvider>
    <AdminDashboardContent />
  </CreditProvider>
);

export default AdminDashboard;
