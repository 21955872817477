import React from "react";
import { Route, Routes } from "react-router-dom";
import UserPolicyViewer from "./user/components/UserPolicyViewer";
import UserPolicy from "./user/views/UserPolicy";

const AppUserPolicyRouter = () => {
  return (
    <Routes>
      <Route path="" element={<UserPolicy />} />
      <Route path="/User-policy-viewer" element={<UserPolicyViewer />} />
    </Routes>
  );
};

export default AppUserPolicyRouter;
