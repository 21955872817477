import React from 'react';
import './ScormCoursePopup.scss';
import ScormPlayer from './ScormPlayer';

const ScormCoursePopup = ({openScormCourse, setOpenScormCourse, scormURL, userCourseId, isScorm, scormProgressId, setScormProgressId}) => {

  const handleScormPopupClose = () => {
    setOpenScormCourse(false)
}
  return (
    <div className='app-scorm_popup' style={{ right: openScormCourse ? '0': '-100%'}}>
    <div className='app-scorm_popup-close'>
        <button className='finish-button' onClick={handleScormPopupClose} >Close</button>
    </div>
    { openScormCourse ? <ScormPlayer openScormCourse={openScormCourse} scormURL={scormURL} userCourseId={userCourseId} isScorm={isScorm} scormProgressId={scormProgressId} setScormProgressId={()=>setScormProgressId}></ScormPlayer> : <></> }
    </div>
  )
}

export default ScormCoursePopup
