import { setScormData, updateScormData } from "./api.services";
import toast from "react-hot-toast";


const SCORM_API = {
    data: {},
    
    LMSInitialize: function() {
        console.log("LMSInitialize called");
        return "true";
    },
    LMSFinish: function() {
        console.log("LMSFinish called");
        return "true";
    },
    LMSGetValue: function(key) {
        console.log(`LMSGetValue called with key: ${key}`);
        if(this.data[key]){
            return this.data[key];
        }
        else{
            return '';
        }
    },
    LMSSetValue: function(key, value) {
        console.log(`LMSSetValue called with key: ${key}, value: ${value}`);
        this.data[key]=value;
        return "true";
    },
    LMSCommit: async function() {
        console.log("LMSCommit called");
        let header = {
            Authorization: "Token " + this.data.Token,
        };
        var scormProgress = this.data['scormProgressId']
        if(!scormProgress){
            const payload = this.data
            await setScormData(header, payload)
            .then((resp) => {
                if(resp.status === 201){
                    this.data['scormProgressId'] = resp.data.id
                }else{
                    toast.error('Unable to submit your course progress!')
                }
            })
        }else{
            const payload = JSON.stringify(this.data)
            await updateScormData(header, scormProgress, payload)
            .then((resp) => {
                if(resp.status === 200){
                    console.log("Progress Saved")
                }else{
                    toast.error('Unable to update your course progress!')
                }
            })
        }
        return "true";
    },
    LMSGetLastError: function() {
        return "0"; // No error
    },
    LMSGetErrorString: function(errorCode) {
        return "No error"; // Just a default message
    },
    LMSGetDiagnostic: function(errorCode) {
        return "No diagnostic information available";
    }
};
  
export default SCORM_API;
  