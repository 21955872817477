import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../components/AdminNavbar";
import CommonSidebar from "../../../common/components/CommonSidebar";
import policyIcon from "../../admin/images/policy.png";
import UserHeader from "../../../common/components/UserHeader";
import "./PolicyUser.scss";
import AppUserPolicyRouter from "../../AppUserPolicyRouter";

const PolicyUser = ({ isAdmin }) => {
  const navigate = useNavigate();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [navbarData, setNavbarData] = useState([
    {
      name: "Policy",
      icon: policyIcon,
      onClick: () => navigate("/policy/user"),
    },
  ]);
  const toggleSidebar = () => {
    setOpenMobileNav(!openMobileNav);
  };

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  return (
    <div className="policy_user">
      <div className="policy_user-body">
        <CommonSidebar
          setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
          navbarData={navbarData}
          deviceWidth={deviceWidth}
          openMobileNav={openMobileNav}
        ></CommonSidebar>

        <div className="policy_user-body-content">
          <div className="policy_user-body-content-header">
            <UserHeader toggleSidebar={toggleSidebar} />
          </div>
          <div className="policy_user-body-content-elements">
            <AppUserPolicyRouter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyUser;
