import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommonSidebar from "../../../common/components/CommonSidebar";
import AdminHeader from "../../../learning/admin/components/AdminHeader";
import dashboardIcon from "../../../learning/images/icons/dashboard.png";
import policyIcon from "../images/policy.png";
import "./PolicyAdmin.scss"
import AppPolicyRouter from "../../AppPolicyRouter";

const PolicyAdmin = () => {
  const navigate = useNavigate();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [navbarData, setNavbarData] = useState([
    {
      name: "Dashboard",
      icon: dashboardIcon,
      onClick: () => navigate("/policy/admin/"),
    },
    {
      name: "Policy",
      icon: policyIcon,
      onClick: () => navigate("/policy/admin/policy/"),
    },
  ]);
  
  const toggleSidebar = () => {
    setOpenMobileNav(!openMobileNav);
  };

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  
  return (
    <div className="policy-admin">
      <div className="policy-admin-body">
        <div className="policy-admin-body-sidebar">
        <CommonSidebar
          setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
          navbarData={navbarData}
          deviceWidth={deviceWidth}
          openMobileNav={openMobileNav}
        ></CommonSidebar>
        </div>
        <div className="policy-admin-body-content">
          <div className="policy-admin-body-content-header">
            <AdminHeader toggleSidebar={toggleSidebar}  />
          </div>
          <div className="policy-admin-body-content-elements">
            <AppPolicyRouter/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyAdmin;
