import React, { useEffect, useState, useRef, useContext } from "react";
import "./CommonSidebar.scss";
import { FaTimes } from "react-icons/fa";
import logo from "../images/logo.png";
import logoutIcon from "../images/logout.png"; // Import the logout icon
import { AuthContext } from "../../App";

const CommonSidebar = ({
  setOpenMobileNavbar,
  navbarData,
  deviceWidth,
  openMobileNav,
}) => {
  const [toggleNavState, setToggleNavState] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const ref = useRef();
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (deviceWidth <= 820) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [deviceWidth]);

  useEffect(() => {
    handleNavbarToggle();
  }, [collapse, openMobileNav]);

  const handleNavbarClick = (event, onClickHandler) => {
    const currentlySelected = document.querySelector(
      ".common-sidebar-body-lists-list.selected"
    );
    if (currentlySelected) {
      currentlySelected.classList.remove("selected");
    }

    const target = event.currentTarget;
    if (!target.classList.contains("selected")) {
      target.classList.add("selected");
    }

    setOpenMobileNavbar(false);
    if (onClickHandler) onClickHandler();
  };

  const handleNavbarToggle = () => {
    const navbar = document.getElementById("collapsible-navbar");
    if (deviceWidth > 450) {
      if (collapse) {
        navbar.style.width = toggleNavState ? "210px" : "65px";
        setToggleNavState(!toggleNavState);
      } else {
        navbar.style.width = "215px";
        setToggleNavState(true);
      }
    } else {
      navbar.style.left = openMobileNav ? "0" : "-100%";
      navbar.style.width = "200px";
    }
  };

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
      payload: {
        user: null,
        token: null,
      },
    });
  };

  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) return;
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  };

  useOnClickOutside(ref, () => setOpenMobileNavbar(false));

  return (
    <div className="common-sidebar" id="collapsible-navbar" ref={ref}>
      <div className="common-sidebar-logo">
        <div className="common-sidebar-logo-img" onClick={handleNavbarToggle}>
          <img src={logo} alt="logo" />
        </div>
        {deviceWidth < 450 ? (
          <button
            className="common-sidebar-collapse-btn"
            onClick={() => setOpenMobileNavbar(false)}
          >
            <FaTimes className="common-sidebar-collapse-btn-close" />
          </button>
        ) : (
          collapse && (
            <button
              className="common-sidebar-collapse-btn"
              onClick={handleNavbarToggle}
            >
              <FaTimes className="common-sidebar-collapse-btn-close" />
            </button>
          )
        )}
      </div>
      <div className="common-sidebar-body">
        <ul className="common-sidebar-body-lists">
          {navbarData.map((item, index) => (
            <li
              key={index}
              className="common-sidebar-body-lists-list"
              onClick={(event) => handleNavbarClick(event, item.onClick)}
            >
              <div className="common-sidebar-body-link-tabs">
                <img
                  src={item.icon}
                  className="common-sidebar-body-link-tabs-img"
                  alt="icons"
                />
                <div
                  className={
                    toggleNavState
                      ? "common-sidebar-body-link-tabs-text responsive-hide"
                      : "common-sidebar-body-link-tabs-text"
                  }
                >
                  {item.name.split(" ", 1)[0]}
                  <br />
                  <span>{item.name.split(" ").slice(1).join(" ")}</span>
                </div>
              </div>
            </li>
          ))}
          {/* Logout Tab */}
          <li
            className="common-sidebar-body-lists-list logout-tab"
            onClick={handleLogout}
          >
            <div className="common-sidebar-body-link-tabs">
              <img
                src={logoutIcon}
                className="common-sidebar-body-link-tabs-img"
                alt="Logout Icon"
              />
              <div
                className={
                  toggleNavState
                    ? "common-sidebar-body-link-tabs-text responsive-hide"
                    : "common-sidebar-body-link-tabs-text"
                }
              >
                Logout
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CommonSidebar;
