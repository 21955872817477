import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaPlus,
  FaTrashAlt,
  FaCloudUploadAlt,
  FaFileExcel,
  FaRegTrashAlt,
  FaChevronDown,
  FaEye,
  FaPencilAlt,
  FaCaretUp,
  FaCaretDown,
  FaFilter,
  FaUpload,
  FaDownload,
} from "react-icons/fa";
import {
  getRecipientListData,
  updateRecipientListData,
  searchRecipientList,
  removeRecipientList,
  createRecipientList,
  createSurvey,
  getSurveyData,
  searchSurveyData,
  removeSurveyData,
  getTemplateData,
  searchTemplateData,
  removeTemplateData,
  downloadRecipientTemplate,
} from "../services/api.services";
import "./AdminSurvey.scss";
import toast from "react-hot-toast";
import AdminCustomCheckbox from "../../../learning/admin/components/AdminCustomCheckbox";
import PopupModal from "../../../learning/admin/components/PopupModal";
import SearchBox from "../../../learning/user/components/SearchBox";
import AppPagination from "../../../learning/admin/components/AppPagination";
import { AuthContext } from "../../../App";

const AdminSurvey = () => {
  const { state, dispatch } = useContext(AuthContext);
  let header = {
    Authorization: "Token " + state.token,
  };
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [recipientListData, setRecipientListData] = useState([]);
  const [recipientLists, setRecipientLists] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [key, setKey] = useState(false);
  const [selectedTab, setSelectedTab] = useState("survey");
  const [checkedData, setCheckedData] = useState([]);
  const itemClass = checkedData.length > 0 ? "active" : "inactive";
  const fileInputRef = useRef(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [isRemove, setIsRemove] = useState(false);
  const [name, setName] = useState("");
  const [surveyFormData, setSurveyFormData] = useState({
    name: "",
    template: "",
    recipient_list: "",
  });
  const [isRecipientListDropdownOpen, setIsRecipientListDropdownOpen] =
    useState(false);
  const [isTemplateDropdownOpen, setIsTemplateDropdownOpen] = useState(false);
  const [selectedRecipientList, setSelectedRecipientList] = useState(
    "Select Recipient List"
  );
  const [selectedTemplate, setSelectedTemplate] = useState("Select Template");
  const [searchKey, setSearchKey] = useState("");

  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  // for editing rows
  const [editingRowId, setEditingRowId] = useState(null);
  const [editedValue, setEditedValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (selectedTab === "recipientList") {
          if (searchKey) {
            response = await searchRecipientList(
              header,
              searchKey,
              pageNo,
              recordsPerPage
            );
          } else {
            response = await getRecipientListData(
              header,
              pageNo,
              recordsPerPage
            );
          }
        } else if (selectedTab === "survey") {
          if (searchKey) {
            response = await searchSurveyData(
              header,
              searchKey,
              pageNo,
              recordsPerPage
            );
          } else {
            response = await getSurveyData(header, pageNo, recordsPerPage);
          }
        } else if (selectedTab === "template") {
          if (searchKey) {
            response = await searchTemplateData(
              header,
              searchKey,
              pageNo,
              recordsPerPage
            );
          } else {
            response = await getTemplateData(header, pageNo, recordsPerPage);
          }
        }

        if (response.status !== "error") {
          setDataCount(response.data.count);

          // Update state based on selected tab
          if (selectedTab === "survey") {
            setSurveyData(response.data.results);
            setRecipientLists(response.data.recipient_lists);
            setTemplates(response.data.templates);
          } else if (selectedTab === "template") {
            setTemplateData(response.data.results);
          } else if (selectedTab === "recipientList") {
            setRecipientListData(response.data.results);
          }
        } else {
          toast.error("Error fetching data: " + response.message);
        }
      } catch (err) {
        toast.error("An unexpected error occurred.");
      }
    };

    fetchData();
  }, [selectedTab, searchKey, pageNo, recordsPerPage, key]);

  const handleFormDataChange = (field, value) => {
    setSurveyFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleNameChange = (e) => {
    handleFormDataChange("name", e.target.value);
  };
  const toggleRecipientListDropdown = () => {
    setIsTemplateDropdownOpen(false);
    setIsRecipientListDropdownOpen(!isRecipientListDropdownOpen);
  };
  const toggleTemplateDropdown = () => {
    setIsRecipientListDropdownOpen(false);
    setIsTemplateDropdownOpen(!isTemplateDropdownOpen);
  };
  const handleSelecteRecipientList = (recipient) => {
    handleFormDataChange("recipient_list", recipient.id);
    setSelectedRecipientList(recipient.name);
    setIsRecipientListDropdownOpen(false);
  };
  const handleSelectTemplate = (template) => {
    handleFormDataChange("template", template.id);
    setSelectedTemplate(template.name);
    setIsTemplateDropdownOpen(false);
  };

  const openPopup = (type) => {
    setPopupType(type);
    setIsOpen(true);
  };

  const handleDropdownToggle = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setCheckedData([]);
    setOpenDropdownId(null);
    setPageNo(1);
  };

  const handleCancelClick = () => {
    setCheckedData([]);
    setUploadError("");
    setIsRemove(false);
  };
  const handleRemoveData = async () => {
    if (checkedData.length === 0) {
      toast.error("No items selected for deletion.");
      return;
    }

    try {
      let response;

      if (selectedTab === "recipientList") {
        response = await removeRecipientList(header, checkedData);
        if (response.status === 200) {
          toast.success("Recipient List deleted successfully.");
        } else {
          toast.error(`Error: ${response.message}`);
        }
      } else if (selectedTab === "survey") {
        response = await removeSurveyData(header, checkedData);
        if (response.status === 200) {
          toast.success("Survey data deleted successfully.");
        } else {
          toast.error(`Error: ${response.message}`);
        }
      } else if (selectedTab === "template") {
        response = await removeTemplateData(header, checkedData);
        if (response.status === 200) {
          toast.success("Template data deleted successfully.");
        } else {
          toast.error(`Error: ${response.message}`);
        }
      }

      setCheckedData([]);
      setIsRemove(false);
      setKey(!key);
    } catch (error) {
      console.error("Failed to delete data:", error);
      toast.error("An error occurred while deleting.");
    }
  };

  const handleSendSurvey = async () => {
    const { name, recipient_list, template } = surveyFormData;

    if (!name || !recipient_list || !template) {
      toast.error("Please fill in all required fields.");
      return;
    }

    const requestData = new FormData();
    requestData.append("name", name);
    requestData.append("recipient_list", recipient_list);
    requestData.append("template", template);

    try {
      const response = await createSurvey(header, requestData);
      if (response.status === 201) {
        toast.success("Survey created successfully.");
        setKey((prevKey) => !prevKey);
        handleCancel();
      } else {
        toast.error(`Error: ${response.message}`);
      }
    } catch (error) {
      console.error("Failed to create survey:", error);
      toast.error("An error occurred while creating the survey.");
    }
  };
  const handleCancel = () => {
    setName("");
    setSelectedRecipientList("");
    setSelectedTemplate("");
    setUploadedFile(null);
    setUploadError("");
    setIsOpen(false);
  };
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
  const ALLOWED_FILE_TYPES = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/plain",
  ];
  const ALLOWED_EXTENSIONS = ["pdf", "docx", "xlsx", "txt", "odt"];
  const handleFileUpload = (files) => {
    const file = files[0];
    setUploadedFile(file);
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
        setUploadError(
          "Unsupported file type. Please upload a PDF, Word, Excel, or plain text file."
        );
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        setUploadError(
          "File size exceeds the 5 MB limit. Please upload a smaller file."
        );
        return;
      }
      setUploadError("");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  const handleCancelUpload = () => {
    setUploadedFile(null);
    setUploadError("");
    setName("");
    fileInputRef.current.value = null;
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  };

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
    return [];
  };
  const handleClearSearch = () => {
    setSearchKey("");
    setKey(!key);
  };
  const handleValueClick = (value) => {
    setSearchKey(value);
  };
  const renderButtonContent = () => {
    switch (selectedTab) {
      case "survey":
        return (
          <>
            <FaPlus />
            New Survey
          </>
        );
      case "template":
        return (
          <>
            <FaFilter />
            Filter
          </>
        );
      case "recipientList":
        return "Upload";
      default:
        return null;
    }
  };
  const getButtonClass = () => {
    return selectedTab === "template" ? "secondary-button" : "primary-button";
  };
  const handleUploadRecipientList = async () => {
    if (!name || !uploadedFile) {
      setUploadError("Please provide both name and file.");
      return;
    }
    try {
      const response = await createRecipientList(header, name, uploadedFile);
      if (response.data.isSuccess) {
        toast.success(response.data.message);
        setKey(!key);
        setIsOpen(false);
        // Clear the input values
        setUploadedFile(null);
        setUploadError("");
        setName("");
        fileInputRef.current.value = null;
      } else {
        setUploadError(
          response.data.message || "Failed to upload recipient list."
        );
      }
    } catch (error) {
      setUploadError("An error occurred while uploading the recipient list.");
    }
  };

  const handleDetailClick = (data) => {
    if (selectedTab === "template") {
      navigate("/compliance/admin/survey-templates", { state: data });
    }
    if (selectedTab === "recipientList") {
      navigate("/compliance/admin/survey-recipient_list", { state: data });
    }
    if (selectedTab === "survey") {
      navigate("/compliance/admin/survey-details", { state: data });
    }
  };
  const handleFileDownload = (fileUrl, fileName) => {
    try {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // toast.success("File downloaded successfully!");
    } catch (error) {
      toast.error("Failed to download the file.");
    }
  };
  const handleEditClick = (item) => {
    setEditingRowId(item.id);
    setEditedValue(item.name);
  };
  const handleConfirmEdit = async (item) => {
    const updatedData = { name: editedValue };

    const response = await updateRecipientListData(
      header,
      item.id,
      updatedData
    );

    if (response.status === 200) {
      toast.success("Updated Successfully!!");
      setKey(!key);
      setEditingRowId(null);
    } else {
      toast.error("Updation Failed!!");
      console.error(response.message);
    }
  };
  const handleCancelEdit = () => {
    setEditingRowId(null);
  };
  const handleDownloadTemplate = async () => {
    const res = await downloadRecipientTemplate(header);

    if (res.status === "error") {
      toast.error("Failed to download template");
      return;
    }
    const contentType = res.headers["content-type"];
    const fileBlob = new Blob([res.data], { type: contentType });
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "recipient_template.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    toast.success("Template downloaded successfully");
  };
  return (
    <div className="admin-survey">
      <div className="admin-survey-title">Survey</div>
      <div className="admin-survey-tabs">
        <div
          className={`admin-survey-tabs-tab ${
            selectedTab === "survey" ? "active" : ""
          }`}
          onClick={() => handleTabClick("survey")}
        >
          Survey
        </div>
        <div
          className={`admin-survey-tabs-tab ${
            selectedTab === "template" ? "active" : ""
          }`}
          onClick={() => handleTabClick("template")}
        >
          Template
        </div>
        <div
          className={`admin-survey-tabs-tab ${
            selectedTab === "recipientList" ? "active" : ""
          }`}
          onClick={() => handleTabClick("recipientList")}
        >
          Recipient List
        </div>
      </div>

      <div className="admin-survey-body">
        <div className="admin-survey-body-tab-content">
          <div className="admin-survey-body-tab-content-top">
          {selectedTab !== "template" &&(
            <button
              className={getButtonClass()}
              onClick={() => openPopup(selectedTab)}
              style={{ width: "145px" }}
            >
              {renderButtonContent()}
            </button>
          )}
            
            <div className="admin-survey-body-tab-content-top-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleSearch}
                onValueClick={handleValueClick}
                onClearSearch={handleClearSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>
          <div className="responsive-container">
            <div className="admin-survey-body-tab-content-table">
              <div className="admin-survey-body-tab-content-table-head">
                {selectedTab === "survey" && (
                  <>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      <AdminCustomCheckbox
                        selectAll={true}
                        setCheckedData={setCheckedData}
                        checkedData={checkedData}
                        responseData={surveyData}
                      />
                      Survey Name
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      Questions
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      Responses
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      Completion Rate
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      Status
                    </div>
                  </>
                )}
                {selectedTab === "template" && (
                  <>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      <AdminCustomCheckbox
                        selectAll={true}
                        setCheckedData={setCheckedData}
                        checkedData={checkedData}
                        responseData={templateData}
                      />
                      Template Name
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      No. of Questions
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      Created Date
                    </div>
                  </>
                )}
                {selectedTab === "recipientList" && (
                  <>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      <AdminCustomCheckbox
                        selectAll={true}
                        setCheckedData={setCheckedData}
                        checkedData={checkedData}
                        responseData={recipientListData}
                      />
                      Name
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      Date
                    </div>
                    <div className="admin-survey-body-tab-content-table-head-item">
                      File
                    </div>
                  </>
                )}
                <div className="admin-survey-body-tab-content-table-head-item">
                  <FaRegTrashAlt
                    onClick={() => {
                      checkedData.length > 0
                        ? setIsRemove(true)
                        : handleRemoveData();
                    }}
                    className={itemClass}
                  />
                </div>
              </div>

              <div className="admin-survey-body-tab-content-table-body">
                {selectedTab === "survey" &&
                  surveyData.map((item) => (
                    <div
                      className="admin-survey-body-tab-content-table-body-row"
                      key={item.id}
                    >
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        <AdminCustomCheckbox
                          selectAll={false}
                          data_id={item.id}
                          setCheckedData={setCheckedData}
                          checkedData={checkedData}
                        />
                        <div className="admin-survey-body-tab-content-table-body-row-item-text">
                          <div className="admin-survey-body-tab-content-table-body-row-item-text-main">
                            {item.name}
                          </div>
                          <div className="admin-survey-body-tab-content-table-body-row-item-text-sub">
                            Last modified on {formatDate(item.updated_on)}
                          </div>
                        </div>
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        {item.question_count}
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        {item.response_count}
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        {item.completion_rate}%
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        {item.status}
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        <div
                          className={`admin-survey-body-tab-content-table-body-row-item-button ${
                            openDropdownId === item.id ? "open" : ""
                          }`}
                          // style={{ width: "120px" }}
                          // onClick={() => handleDropdownToggle(item.id)}
                          onClick={() => handleDetailClick(item)}
                        >
                          {/* Action <FaChevronDown /> */}
                          <FaEye /> 
                        </div>
                        <div
                          className={`admin-survey-body-tab-content-table-body-row-item-dropdown-menu ${
                            openDropdownId === item.id ? "open" : ""
                          }`}
                        >
                          <div
                            className="admin-survey-body-tab-content-table-body-row-item-dropdown-menu-item"
                            onClick={() => handleDetailClick(item)}
                          >
                            <FaEye /> View Details
                          </div>
                          <div className="admin-survey-body-tab-content-table-body-row-item-dropdown-menu-item">
                            <FaPencilAlt /> Edit
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {selectedTab === "template" &&
                  templateData.map((item) => (
                    <div
                      className="admin-survey-body-tab-content-table-body-row"
                      key={item.id}
                    >
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        <AdminCustomCheckbox
                          selectAll={false}
                          data_id={item.id}
                          setCheckedData={setCheckedData}
                          checkedData={checkedData}
                        />
                        <div className="admin-survey-body-tab-content-table-body-row-item-text">
                          <div className="admin-survey-body-tab-content-table-body-row-item-text-main">
                            {item.name}
                          </div>
                          <div className="admin-survey-body-tab-content-table-body-row-item-text-sub">
                            Last modified on {formatDate(item.updated_on)}
                          </div>
                        </div>
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        {item.question_count}
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        {formatDate(item.created_on)}
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        <div
                          className={` admin-survey-body-tab-content-table-body-row-item-button ${
                            openDropdownId === item.id ? "open" : ""
                          }`}
                          onClick={() => handleDetailClick(item)}
                        >
                          <FaEye />
                        </div>
                        <div
                          className={`admin-survey-body-tab-content-table-body-row-item-dropdown-menu ${
                            openDropdownId === item.id ? "open" : ""
                          }`}
                        >
                          <div
                            className="admin-survey-body-tab-content-table-body-row-item-dropdown-menu-item"
                            onClick={() => handleDetailClick(item)}
                          >
                            <FaEye /> View Details
                          </div>
                          <div className="admin-survey-body-tab-content-table-body-row-item-dropdown-menu-item">
                            <FaPencilAlt /> Edit
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {selectedTab === "recipientList" &&
                  recipientListData.map((item) => (
                    <div
                      className="admin-survey-body-tab-content-table-body-row"
                      key={item.id}
                    >
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        <AdminCustomCheckbox
                          selectAll={false}
                          data_id={item.id}
                          setCheckedData={setCheckedData}
                          checkedData={checkedData}
                        />
                        <div className="admin-survey-body-tab-content-table-body-row-item-text">
                          {editingRowId === item.id ? (
                            <input
                              type="text"
                              value={editedValue}
                              onChange={(e) => setEditedValue(e.target.value)}
                            />
                          ) : (
                            <>
                              <div className="admin-survey-body-tab-content-table-body-row-item-text-main">
                                {item.name}
                              </div>
                              <div className="admin-survey-body-tab-content-table-body-row-item-text-sub">
                                Last modified on {formatDate(item.updated_on)}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        {formatDate(item.created_on)}
                      </div>
                      <div className="admin-survey-body-tab-content-table-body-row-item">
                        {/* <span className="link" onClick={() => handleDetailClick(item)}>{item.name}</span> */}
                        <span
                          className="link"
                          onClick={() =>
                            handleFileDownload(item.file, `${item.name}.xlsx`)
                          }
                        >
                          {item.name}
                        </span>
                      </div>
                      <div
                        className="admin-survey-body-tab-content-table-body-row-item"
                        style={{ gap: "5px" }}
                      >
                        {editingRowId === item.id ? (
                          <>
                            <button
                              className="primary-button"
                              style={{ width: "85px" }}
                              onClick={() => handleConfirmEdit(item)}
                            >
                              Confirm
                            </button>
                            <button
                              className="secondary-button"
                              style={{ width: "80px" }}
                              onClick={handleCancelEdit}
                            >
                              Cancel
                            </button>
                          </>
                        ) : (
                          <button
                            className="secondary-button"
                            onClick={() => handleEditClick(item)}
                          >
                            <FaPencilAlt /> Edit
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <PopupModal setIsOpen={setIsOpen}>
          <div className="admin-survey-modal">
            <div className="admin-survey-modal-title">
              {popupType === "survey" ? "New Survey" : "Upload File"}
            </div>
            {popupType === "survey" ? (
              <>
                <div className="admin-survey-modal-content">
                  <div className="admin-survey-modal-content-form">
                    <label
                      className="admin-survey-modal-content-form-label"
                      htmlFor="name"
                    >
                      Survey Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={surveyFormData.name}
                      onChange={handleNameChange}
                      className="admin-survey-modal-content-form-input"
                      style={{ background: "white" }}
                    />
                  </div>
                  <div className="admin-survey-modal-content-form">
                    <label
                      className="admin-survey-modal-content-form-label"
                      htmlFor="recipient_list"
                    >
                      Recipient List
                    </label>

                    <div className="dropdown-container">
                      <div
                        className="input-dropdown"
                        style={{ margin: "5px 0 15px" }}
                        onClick={toggleRecipientListDropdown}
                      >
                        <span>{selectedRecipientList}</span>
                        <span>
                          {isRecipientListDropdownOpen ? (
                            <FaCaretUp />
                          ) : (
                            <FaCaretDown />
                          )}
                        </span>
                      </div>
                      {isRecipientListDropdownOpen && (
                        <div className="dropdown-list">
                          {recipientLists &&
                            recipientLists.map((recipient) => (
                              <div
                                key={recipient.id}
                                className="dropdown-item"
                                onClick={() =>
                                  handleSelecteRecipientList(recipient)
                                }
                              >
                                {recipient.name}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="admin-survey-modal-content-form">
                    <label
                      className="admin-survey-modal-content-form-label"
                      htmlFor="recipient_list"
                    >
                      Template
                    </label>

                    <div className="dropdown-container">
                      <div
                        className="input-dropdown"
                        style={{ margin: "5px 0 15px" }}
                        onClick={toggleTemplateDropdown}
                      >
                        <span>{selectedTemplate}</span>
                        <span>
                          {isTemplateDropdownOpen ? (
                            <FaCaretUp />
                          ) : (
                            <FaCaretDown />
                          )}
                        </span>
                      </div>
                      {isTemplateDropdownOpen && (
                        <div className="dropdown-list">
                          {templates &&
                            templates.map((template) => (
                              <div
                                key={template.id}
                                className="dropdown-item"
                                onClick={() => handleSelectTemplate(template)}
                              >
                                {template.name}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="admin-survey-modal-bottom">
                  <button className="primary-button" onClick={handleSendSurvey}>
                    Send Survey
                  </button>
                  <button className="secondary-button" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="admin-survey-modal-content">
                  <div className="admin-survey-modal-content-form">
                    <label
                      className="admin-survey-modal-content-form-label"
                      htmlFor="name"
                    >
                      File Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="admin-survey-modal-content-form-input"
                      style={{ background: "white" }}
                    />
                  </div>
                  <div
                    className="admin-survey-modal-content-body"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    style={{ background: "white" }}
                  >
                    {!uploadedFile ? (
                      <>
                        <div className="admin-survey-modal-content-body-top">
                          <div
                            className="admin-survey-modal-content-body-top-download_button"
                            onClick={handleDownloadTemplate}
                          >
                            <div className="admin-survey-modal-content-body-top-download_button-icon">
                              <FaDownload />
                            </div>
                            <div className="admin-survey-modal-content-body-top-download_button-text">
                              Get Template
                            </div>
                          </div>
                        </div>
                        <div className="admin-survey-modal-content-body-bottom">
                          <div className="admin-survey-modal-content-body-bottom-icon">
                            <FaCloudUploadAlt />
                          </div>
                          <div className="admin-survey-modal-content-body-bottom-drag_text">
                            Drag and drop files to upload
                          </div>
                          <div className="admin-survey-modal-content-body-bottom-subtext">
                            or
                          </div>
                          <button
                            className="primary-button"
                            onClick={() => fileInputRef.current.click()}
                          >
                            Browse Files
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileInputChange}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="admin-survey-modal-content-body-uploaded">
                        <div className="admin-survey-modal-content-body-uploaded-file">
                          <div className="admin-survey-modal-content-body-uploaded-file-icon">
                            <FaFileExcel />
                          </div>
                          <div className="admin-survey-modal-content-body-uploaded-file-info">
                            <div className="admin-survey-modal-content-body-uploaded-file-info-name">
                              {uploadedFile.name}
                            </div>
                            <div className="admin-survey-modal-content-body-uploaded-file-info-size">
                              {formatFileSize(uploadedFile.size)}
                            </div>
                          </div>
                        </div>

                        <div className="admin-survey-modal-content-body-uploaded-action">
                          {uploadError && (
                            <div className="admin-survey-modal-content-body-uploaded-action-error-message">
                              {uploadError}
                            </div>
                          )}
                          <div
                            className="admin-survey-modal-content-body-uploaded-action-upload_button"
                            onClick={handleUploadRecipientList}
                          >
                            <div className="admin-survey-modal-content-body-uploaded-action-upload_button-icon">
                              <FaUpload />
                            </div>
                            <div className="admin-survey-modal-content-body-uploaded-action-upload_button-text">
                              Upload
                            </div>
                          </div>
                          <div
                            className="admin-survey-modal-content-body-uploaded-action-cancel_button"
                            onClick={handleCancelUpload}
                          >
                            <div className="admin-survey-modal-content-body-uploaded-action-cancel_button-icon">
                              <FaTrashAlt />
                            </div>
                            <div className="admin-survey-modal-content-body-uploaded-action-cancel_button-text">
                              Remove
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </PopupModal>
      )}
      {isRemove && (
        <PopupModal setIsOpen={setIsRemove}>
          <div className="unenroll_confirm_modal-body">
            <div className="unenroll_confirm_modal-body-title">Remove Data</div>
            <div className="unenroll_confirm_modal-body-text">
              Are you sure you want to remove selected{" "}
              {checkedData.length < 2 ? "row" : "rows"}?
            </div>
            <div className="unenroll_confirm_modal-body-buttons">
              <button
                className="primary-button"
                onClick={() => {
                  handleRemoveData();
                }}
              >
                Confirm
              </button>
              <button className="secondary-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminSurvey;
