import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonSidebar from "../../../common/components/CommonSidebar";
import AdminHeader from "../../../learning/admin/components/AdminHeader";
import staffIcon from "../images/staff.png"
import "./UsersAdmin.scss"
import AppUsersRouter from "../../AppUsersRouter";

const UsersAdmin = () => {
  const navigate = useNavigate();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [navbarData, setNavbarData] = useState([
    {
      name: "Staff Management",
      icon: staffIcon,
      onClick: () => navigate("/users/admin/"),
    },
  ]);
  
  const toggleSidebar = () => {
    setOpenMobileNav(!openMobileNav);
  };

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  
  return (
    <div className="users-admin">
      <div className="users-admin-body">
        <div className="users-admin-body-sidebar">
        <CommonSidebar
          setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
          navbarData={navbarData}
          deviceWidth={deviceWidth}
          openMobileNav={openMobileNav}
        ></CommonSidebar>
        </div>
        <div className="users-admin-body-content">
          <div className="users-admin-body-content-header">
            <AdminHeader toggleSidebar={toggleSidebar}  />
          </div>
          <div className="users-admin-body-content-elements">
            <AppUsersRouter/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersAdmin;
