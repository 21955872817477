import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CommonSidebar from "../../../common/components/CommonSidebar";
import AdminHeader from "../../../learning/admin/components/AdminHeader";
import surveyIcon from "../images/survey.png";
import dashboardIcon from "../../../learning/images/icons/dashboard.png";
import "./ComplianceAdmin.scss";
import AppComplianceRouter from "../../AppComplianceRouter";

const ComplianceAdmin = () => {
  const navigate = useNavigate();
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());
  const [navbarData, setNavbarData] = useState([
    {
      name: "Dashboard",
      icon: dashboardIcon,
      onClick: () => navigate("/compliance/admin/"),
    },
    {
      name: "Survey",
      icon: surveyIcon,
      onClick: () => navigate("/compliance/admin/survey/"),
    },
  ]);

  const toggleSidebar = () => {
    setOpenMobileNav(!openMobileNav);
  };

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  return (
    <div className="compliance-admin">
      <div className="compliance-admin-body">
        <div className="compliance-admin-body-sidebar">
          <CommonSidebar
            setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
            navbarData={navbarData}
            deviceWidth={deviceWidth}
            openMobileNav={openMobileNav}
          />
        </div>
        <div className="compliance-admin-body-content">
          <div className="compliance-admin-body-content-header">
            <AdminHeader toggleSidebar={toggleSidebar} />
          </div>
          <div className="compliance-admin-body-content-elements">
            <AppComplianceRouter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplianceAdmin;
