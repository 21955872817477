import { useEffect, useRef, useState } from 'react';

const SCORM_TRUE = "true";
const SCORM_FALSE = "false";
const SCORM_NO_ERROR = "0";

function ScormAPIWrapper() {
    // const [api, setApi] = useState({})
    const [finishCalled, setFinishCalled] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const apiRef = useRef(null);
    // const initialized = useRef(false);
    // const finishCalled = useRef(false);

    const findAPI = (win) => {
        let findAPITries = 0;

        while ( (win.API === null) &&
                (win.parent !== null) &&
                (win.parent !== win) )
        {
            // increment the number of findAPITries
            findAPITries++;

            // Note: 7 is an arbitrary number, but should be more than sufficient
            if (findAPITries > 7)
            {
                alert("Error finding API -- too deeply nested.");
                return null;
            }

            // set the variable that represents the window being
            // being searched to be the parent of the current window
            // then search for the API again
            win = win.parent;
        }
        return win.API;

    }

    const getAPI = () => {
        if(!apiRef.current){
            // start by looking for the API in the current window
            var theAPI = findAPI(window);

            // if the API is null (could not be found in the current window)
            // and the current window has an opener window
            if ( (theAPI === null) &&
                    (window.opener !== null) &&
                    (typeof(window.opener) !== "undefined") )
            {
                // try to find the API in the current window�s opener
                theAPI = findAPI(window.opener);
            }
            // if the API has not been found
            if (theAPI === null)
            {
                // Alert the user that the API Adapter could not be found
                alert("Unable to find an API adapter");
                return null;
            }
            apiRef.current = theAPI;
        }
        
        return apiRef.current;
    }

    useEffect(() => {
        const api = getAPI();
        if (api) {
            console.log('SCORM API found:', api);
        }
    }, []);


    const initialize = () => {
        var result;
        const api = getAPI();

        if (api === null){
            alert("ERROR - Could not establish a connection with the LMS.\n\nYour results may not be recorded.");
            return;
        }
        
        result = api.LMSInitialize("");
        
        if (result === SCORM_FALSE){
            var errorNumber = api.LMSGetLastError();
            var errorString = api.LMSGetErrorString(errorNumber);
            var diagnostic = api.LMSGetDiagnostic(errorNumber);
            
            var errorDescription = "Number: " + errorNumber + "\nDescription: " + errorString + "\nDiagnostic: " + diagnostic;
            
            alert("Error - Could not initialize communication with the LMS.\n\nYour results may not be recorded.\n\n" + errorDescription);
            return false;
        }
        
        setInitialized(true);
        console.log('SCORM initialized successfully.')
        return true;
    }

    const terminate = () => {
        var result;
        const api = getAPI();
        //Don't terminate if we haven't this.initialized or if we've already terminated
        if (initialized === false || finishCalled === true){return;}
        
        result = api.LMSFinish("");
        
        setFinishCalled(true);
        
        if (result === SCORM_FALSE){
            var errorNumber = api.LMSGetLastError();
            var errorString = api.LMSGetErrorString(errorNumber);
            var diagnostic = api.LMSGetDiagnostic(errorNumber);
            
            var errorDescription = "Number: " + errorNumber + "\nDescription: " + errorString + "\nDiagnostic: " + diagnostic;
            
            alert("Error - Could not terminate communication with the LMS.\n\nYour results may not be recorded.\n\n" + errorDescription);
        }
        console.log('SCORM terminated.');
    }

    const getValue = (element) => {
        var result;
        const api = getAPI();
        if (!api || initialized === false || finishCalled === true){return '';}
        
        result = api.LMSGetValue(element);
        
        if (result === ""){
        
            var errorNumber = api.LMSGetLastError();
            
            if (errorNumber !== SCORM_NO_ERROR){
                var errorString = api.LMSGetErrorString(errorNumber);
                var diagnostic = api.LMSGetDiagnostic(errorNumber);
                
                var errorDescription = "Number: " + errorNumber + "\nDescription: " + errorString + "\nDiagnostic: " + diagnostic;
                
                alert("Error - Could not retrieve a value from the LMS.\n\n" + errorDescription);
                return "";
            }
        }
        
        return result;
    }

    const setValue = (element, value) => {
        var result;
        const api = getAPI();
        if (!api || initialized === false || finishCalled === true){return false;}
        
        result = api.LMSSetValue(element, value);
        
        if (result === SCORM_FALSE){
            var errorNumber = api.LMSGetLastError();
            var errorString = api.LMSGetErrorString(errorNumber);
            var diagnostic = api.LMSGetDiagnostic(errorNumber);
            
            var errorDescription = "Number: " + errorNumber + "\nDescription: " + errorString + "\nDiagnostic: " + diagnostic;
            
            alert("Error - Could not store a value in the LMS.\n\nYour results may not be recorded.\n\n" + errorDescription);
            return false;
        }
        return true;
    }

    const commit = () => {
        const api = getAPI();
        if (!api || !initialized) return false;
        const result = api.LMSCommit('');
        if (result === SCORM_FALSE) {
            console.error('Error committing SCORM data:', api.LMSGetLastError());
            return false;
        }
        return true;
    }

  return {
    initialize,
    terminate,
    getValue,
    setValue,
    commit,
    initialized: initialized,
    finishCalled: finishCalled,
  }
}

export default ScormAPIWrapper
